import React from 'react'
import RichTextRenderer from '../components/RichTextRender'
import Layout from '../components/Layout'
// import SEO from "../components/Seo"

const RichTextPage = props => {
  const { content } = props.pageContext
  return (
    <Layout languageRedirect={true}>
      {/* <SEO title="Home" /> */}
      <div className="rich-text-page">
        <RichTextRenderer node={content} />
      </div>
    </Layout>
  )
}

export default RichTextPage
